export const footerItems = [
    [
      { href: '/ace-projects-in-noida.php', projectName: 'ACE Projects in Noida' },
      { href: '/ats-projects-in-noida.php', projectName: 'ATS Projects in Noida' },
      { href: '/godrej-projects-in-noida.php', projectName: 'Godrej Projects in Noida' },
      { href: '/godrej-projects-in-gurgoan.php', projectName: 'Godrej Projects in Gurgoan' },
      { href: '/property-in-yamuna-expressway.php', projectName: 'Property in Yamuna Expressway' },
      { href: '/flats-in-gurugram-for-sale.php', projectName: 'Flats in Gurugram for sale' },
      { href: '/max-estate-projects-in-gurugram.php', projectName: 'Max Estate Projects In Gurugram' },
    ],
    [
      { href: '/flats-in-gurgaon.php', projectName: 'Flats in Gurgaon' },
      { href: '/flats-in-greater-noida.php', projectName: 'Flats in Greater Noida' },
      { href: '/flats-in-noida-for-sale.php', projectName: 'Flats for Sale in Noida' },
      { href: '/flats-in-mumbai-for-sale.php', projectName: 'New Flats in Mumbai for Sale' },
      { href: '/flats-in-pune-for-sale.php', projectName: 'Flats for Sale in Pune' },
      { href: '/flats-in-delhi-for-sale.php', projectName: 'Flats in Delhi for sale' },
      { href: '/m3m-new-projects-in-gurgaon.php', projectName: 'M3M new projects in Gurgaon' },
    ],
    [
      { href: '/commercial-property-in-noida.php', projectName: 'Commercial Property in Noida' },
      { href: '/commercial-property-in-greater-noida.php', projectName: 'Commercial Property in Greater Noida' },
      { href: '/new-projects-in-noida-extension.php', projectName: 'New Projects in Noida Extension' },
      { href: '/new-residential-projects-in-noida.php', projectName: 'New Residential Projects in Noida' },
      { href: '/residential-projects-in-sector-150-noida.php', projectName: 'Residential Projects in Sector 150 Noida' },
      { href: '/new-launch-projects-in-dwarka-expressway.php', projectName: 'New Launch Projects In Dwarka Expressway' },
      { href: '/new-launch-projects-in-gurugram.php', projectName: 'New Launch Projects in Gurugram' },
    ],
    [
      { href: '/2-bhk-flat-in-noida-extension-ready-to-move.php', projectName: '2BHK Flats in Noida Extension' },
      { href: '/3-bhk-flats-in-noida-extension-ready-to-move.php', projectName: '3BHK Flats in Noida Extension' },
      { href: '/3bhk-flat-in-gurugram.php', projectName: '3BHK Flats in Gurugram' },
      { href: '/4bhk-flats-in-gurugram.php', projectName: '4BHK Flats in Gurugram' },
      { href: '/new-launch-residential-projects-in-gurgaon.php', projectName: 'New Residential Projects in Gurgaon' },
      { href: '/best-residential-projects-in-greater-noida-west.php', projectName: 'Residential Projects in Greater Noida West' },
      { href: '/best-residential-projects-in-siddharth-vihar-ghaziabad.php', projectName: 'Residential Projects in Siddharth Vihar' },
    ],
  ];
  
 // Named exports